Array.prototype.dj_containObjectById = function(id) {
  let result = this.dj_checkOutObjectByKeyAndValue('id',id);
  if (result.length >0){return true;}
  else {return false;}
}

Array.prototype.dj_checkOutObjectByKeyAndValue = function(key = '',value,total = false) {
  let mapper = [];
  this.filter(function(item,index){
    if (item[key] !== undefined){if (item[key].toString() === value.toString()){mapper.push(item);}}
  })
  if (total){return mapper;}
  else {
    if (mapper.length > 0){return mapper[0];}
    else {return undefined;}
  }
}
