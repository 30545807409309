import './string.number'
import './string.array'
import './string.file'
import './string.date'

String.prototype.dj_containSubString = function(subString) {
    return this.indexOf(subString) >= 0;
}
String.prototype.dj_slice = function(start = 0,end = 1) {
    return this.slice(start, end);
}




export function dj_identifier(len = 32, firstU = true, radix = null) {
    let chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');
    let uuid = [];
    radix = radix || chars.length;
    if (len) {
        for (let i = 0; i < len; i++) uuid[i] = chars[0 | Math.random() * radix];
    } else {
        let r;
        uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
        uuid[14] = '4';
        for (let i = 0; i < 36; i++) {
            if (!uuid[i]) {
                r = 0 | Math.random() * 16;
                uuid[i] = chars[(i === 19) ? (r & 0x3) | 0x8 : r];
            }
        }
    }
    if (firstU) {
        uuid.shift();
        return 'dj' + uuid.join('');
    } else {
        return uuid.join('');
    }
}
String.prototype.dj_trim = function (pos = 'both') {
    if (pos === 'both') {
        return this.replace(/(^\s*)|(\s*$)/g, '');
    } else if (pos === "left") {
        return this.replace(/^\s*/, '');
    } else if (pos === 'right') {
        return this.replace(/(\s*$)/g, "");
    } else if (pos === 'all') {
        return this.replace(/\s+/g, "");
    } else {
        return this;
    }
}






// HTML编码
// String.prototype.dj_HTMLEncode = function() {
//     var re = this;
//     var q1 = [ /x26/g, /x3C/g, /x3E/g, /x20/g ];
//     var q2 = [ "&", "<", ">", " " ];
//     for ( var i = 0; i < q1.length; i++)
//         re = re.replace(q1[i], q2[i]);
//     return re;
// }
// //逆序
// String.prototype.dj_reverse = function() {
//     return this.split("").reverse().join("");
// }
// /**
//  * 是否包含某个字符串
//  * **/
// String.prototype.dj_containSubString = function(subString) {
//     return this.indexOf(subString) >= 0;
// }
//
// // 合并多个空白为一个空白
// String.prototype.resetBlank = function() {
//     return this.replace(/s+/g, " ");
// }
// // 除去左边空白
// String.prototype.LTrim = function() {
//     return this.replace(/^s+/g, "");
// }
// // 除去右边空白
// String.prototype.RTrim = function() {
//     return this.replace(/s+$/g, "");
// }
// // 除去两边空白
// String.prototype.trim = function() {
//     return this.replace(/(^s+)|(s+$)/g, "");
// }
// // 保留数字
// String.prototype.getNum = function() {
//     return this.replace(/[^d]/g, "");
// }
// // 保留字母
// String.prototype.getEn = function() {
//     return this.replace(/[^A-Za-z]/g, "");
// }
// // 保留中文
// String.prototype.getCn = function() {
//     return this.replace(/[^u4e00-u9fa5uf900-ufa2d]/g, "");
// }
// // 得到字节长度
// String.prototype.getRealLength = function() {
//     return this.replace(/[^x00-xff]/g, "--").length;
// }
// // 从左截取指定长度的字串
// String.prototype.left = function(n) {
//     return this.slice(0, n);
// }
// // 从右截取指定长度的字串
// String.prototype.right = function(n) {
//     return this.slice(this.length - n);
// }
//
// // Unicode转化
// String.prototype.ascW = function() {
//     var strText = "";
//     for ( var i = 0; i < this.length; i++)
//         strText += "&#" + this.charCodeAt(i) + ";";
//     return strText;
// }
