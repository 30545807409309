import router from './router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

router.beforeEach(async (to, from, next) => {
    NProgress.start();
    next();
    NProgress.done();

})

/*注册一个全局守卫*/
router.beforeResolve((to, from, next) => {
    next();
})

/*全局后置钩子*/
router.afterEach(() => {
    //每次请求结束后都需要关闭进度条
    NProgress.done()

})
