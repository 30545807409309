import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import('@/@pages/@@router/@@root/ctrl/UiRoot.vue'),
    redirect:{name:'UiArticleHomeCtrl'},
    children:[
      {
        path: '/home',
        name: 'UiArticleHomeCtrl',
        component: () => import('@/@pages/article/@home/ctrl/UiArticleHomeCtrl.vue'),
      },
      {
        path: '/article/info/:article_id',
        name: 'UiArticleInfoCtrl',
        component: () => import('@/@pages/article/info/ctrl/UiArticleInfoCtrl.vue'),
      },
      {
        path: '/channel/:channel_id',
        name: 'UiArticleChannelCtrl',
        component: () => import('@/@pages/article/channel/ctrl/UiArticleChannelCtrl.vue'),
      },
      {
        path: '/project',
        name: 'UiArticleProjectCtrl',
        component: () => import('@/@pages/article/project/index/ctrl/UiArticleProjectCtrl.vue'),
      },
      {
        path: '/project/:project_id',
        name: 'UiProjectInfoCtrl',
        component: () => import('@/@pages/article/project/info/ctrl/UiProjectInfoCtrl.vue'),
      },
      {
        path: '/search',
        name: 'UiArticleSearchCtrl',
        component: () => import('@/@pages/article/search/index/ctrl/UiArticleSearchCtrl.vue'),
      },
      {
        path: '/search/result/:searchText/:type',
        name: 'UiArticleSearchResultCtrl',
        component: () => import('@/@pages/article/search/info/ctrl/UiArticleSearchResultCtrl.vue'),
      },
      {
        path: '/live',
        name: 'UiLiveIndexCtrl',
        component: () => import('@/@pages/article/live/index/ctrl/UiLiveIndexCtrl.vue'),
      },
      {
        path: '/live/info/:id',
        name: 'UiLiveInfoCtrl',
        component: () => import('@/@pages/article/live/info/ctrl/UiLiveInfoCtrl.vue'),
      },

    ]
  },
  {
    path: '/paper',
    component: () => import('@/@pages/@@router/@@root/ctrl/UiPaperRoot.vue'),
    redirect:{name:'UiPaperHomeCtrl'},
    children:[
      {
        path: '/paper/home',
        name: 'UiPaperHomeCtrl',
        component: () => import('@/@pages/paper/@home/ctrl/UiPaperHomeCtrl.vue'),
      },
      {
        path: '/paper/main/:id',
        name: 'UiPaperMainCtrl',
        component: () => import('@/@pages/paper/main/ctrl/UiPaperMainCtrl.vue'),
      },
      {
        path: '/paper/plate/:id',
        name: 'UiPaperPlateInfoCtrl',
        component: () => import('@/@pages/paper/plate/ctrl/UiPaperPlateInfoCtrl.vue'),
      },
      {
        path: '/paper/article/:id',
        name: 'UiPaperArticleInfoCtrl',
        component: () => import('@/@pages/paper/article/ctrl/UiPaperArticleInfoCtrl.vue'),
      },
      {
        path: '/paper/year/:year',
        name: 'UiPaperYearCtrl',
        component: () => import('@/@pages/paper/year/ctrl/UiPaperYearCtrl.vue'),
      },
    ]
  }

]
export const DjPages = [

  'UiRoot',
  'UiArticleHomeCtrl',
  'UiArticleInfoCtrl',
  'UiLiveInfoCtrl',
    'UiPaperRoot',
  'UiPaperHomeCtrl',

];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const originalReplace = VueRouter.prototype.replace;

VueRouter.prototype.replace = function push(location) {
  return originalReplace.call(this, location).catch(err => err);
}


export default router
