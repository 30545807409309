String.prototype.dj_percentageToNumber = function () {
    if (!Object.dj_isString(this)){return 0;}
    let string = this.replace("%","");
    let result = parseFloat(string)/100;
    return result;
}
String.prototype.dj_percentageToPixel = function (pixel = 'px') {
    let string = this.dj_percentageToNumber();
    return string + pixel;
}
String.prototype.dj_pxToNumber = function () {
    return parseInt(this)
}