
Date.dj_todayDate = function(formatStr = 'yyyy-MM-dd HH:mm:ss',offsetMinutes = 0) {
    let timestamp = new Date(new Date().toLocaleDateString()).getTime();
    timestamp = timestamp + offsetMinutes*60*1000;
    return Date.dj_formatDate(timestamp,formatStr);
};
Date.dj_todayInfo = function() {
    let timestamp = new Date(new Date().toLocaleDateString()).getTime();
    let fullDate = Date.dj_formatDate(timestamp,'yyyy-MM-dd HH:mm:ss');
    let year = Date.dj_formatDate(timestamp,'yyyy');
    let month = Date.dj_formatDate(timestamp,'MM');
    let day = Date.dj_formatDate(timestamp,'dd');
    let week = Date.dj_getDateWeek(fullDate);
    return {
        date:fullDate,
        year:year,
        month:month,
        day:day,
        week:week,
    }

};

Date.dj_getNowDate = function(formatStr = 'yyyy-MM-dd HH:mm:ss',$date = '') {
    if (Date.dj_isDate($date)){
        $date = $date.dj_trim('all');
        let date = new Date($date);
        let timestamp = date.getTime();
        return Date.dj_formatDate(new Date(Number(timestamp)),formatStr);
    }
    let timestamp = Date.dj_getNowTimestamp();
    return Date.dj_formatDate(new Date(Number(timestamp)),formatStr);
}

Date.dj_nowTimeStamp = function(offset = 0,isMs = false) {
    if (isMs){
        let timestamp = Date.parse(new Date())/1000;
        timestamp = timestamp + offset;
        return timestamp;
    }else {
        let timestamp = Date.parse(new Date());
        timestamp = timestamp + offset*1000;
        return timestamp;
    }
};
Date.dj_formatDate = function (date, format="yyyy-MM-dd HH:mm:ss") {
    if (!date) return;
    switch(typeof date) {
        case "string":
            date = new Date(date.replace(/-/g, "/"));
            break;
        case "number":
            date = new Date(date);
            break;
    }
    if (!date instanceof Date) return;
    var dict = {
        "yyyy": date.getFullYear(),
        "M": date.getMonth() + 1,
        "d": date.getDate(),
        "H": date.getHours(),
        "m": date.getMinutes(),
        "s": date.getSeconds(),
        "MM": ("" + (date.getMonth() + 101)).substr(1),
        "dd": ("" + (date.getDate() + 100)).substr(1),
        "HH": ("" + (date.getHours() + 100)).substr(1),
        "mm": ("" + (date.getMinutes() + 100)).substr(1),
        "ss": ("" + (date.getSeconds() + 100)).substr(1)
    };
    return format.replace(/(yyyy|MM?|dd?|HH?|ss?|mm?)/g, function() {return dict[arguments[0]];});
}

Date.dj_isDate = function(string = '') {
    if(isNaN(string)&&!isNaN(Date.parse(string))){return true;}
    return false;
}

Date.dj_toTimestamp = function (date){
    date = date.substring(0,19);
    date = date.replace(/-/g,'/');
    return new Date(date).getTime()
}
/**
 * 正数代表：当前时间大于开始时间
 *
 * **/
Date.dj_countDown = function (date) {
    let targetTimestamp = Date.dj_toTimestamp(date)/1000;
    let currentTimestamp = Date.dj_nowTimeStamp()/1000;
    let diff = currentTimestamp - targetTimestamp;
    return diff;
}
Date.dj_isBegin = function (date) {
    let targetTimestamp = Date.dj_toTimestamp(date)/1000;
    let currentTimestamp = Date.dj_nowTimeStamp()/1000;
    let diff = currentTimestamp - targetTimestamp;
    return diff>=0?true:false;
}
Date.dj_getDateWeek = function(date = '') {
    let formatDate = Date.dj_formatDate(date,'yyyy-MM-dd')
    let weekArray = ["日", "一", "二", "三", "四", "五", "六"];
    return  weekArray[new Date(formatDate).getDay()];
}