export function dj_sleep(value = 30) {
    return new Promise((resolve) => {
        setTimeout(() => {resolve();}, value);
    })
}

export function dj_copyObject(object){
    // 判断是否是对象
    if (typeof object !== 'object') return;
    // 判断obj类型，根据类型新建一个对象或者数组
    let newObj = object instanceof Array ? [] : {}
    // 遍历对象，进行赋值
    for (let key in object) {
        if (object.hasOwnProperty(key)) {
            let val = object[key];
            // 判断属性值的类型，如果是对象，递归调用deepCopy
            newObj[key] = typeof val === 'object' ? dj_copyObject(val) : val
        }
    }
    return newObj

}
export function dj_debounce( fn ){
    let time = null ;
    return function( ){
        clearTimeout( time ) ; // 清除定时器
        time = setTimeout( () => {
            fn.apply(this,arguments)
        }, 200)
    }
}

export function dj_isWechat(){
    let ua = navigator.userAgent.toLowerCase();
    let isWechat = ua.indexOf('micromessenger') !== -1;
    if (isWechat){
        return true;
    }

    let plat = navigator.platform;
    let win = plat.indexOf("Win") === 0;
    let mac = plat.indexOf("Mac") === 0;
    if (win || mac) {
        return false
    } else {
        return true
    }

}

